import { PureComponent } from 'react';
import { Metadata } from '../../metadata';
import i10n from '../../i10n';
import { connect } from 'react-redux';
import { CommonLayout } from './layout';
import clear from '../clear';
import { AsyncSVG } from '../AsyncSVG';
import { Redirect } from 'react-router-dom';

class AuthLayout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            cancelRedirectTriggered: false
        }
    }

    handleAuthFormSubmit = (e) => {
        if (this.props.onActionButtonClick) {
            this.props.onActionButtonClick(e);
        }
    }

    render() {

        var errors = [];

        for (var id in this.props.errorMessages) {
            errors.push(<li key={id} >{this.props.errorMessages[id]}</li>);
        }

        if (this.state.cancelRedirectTriggered) {
            return <Redirect to={this.props.onCancelRedirect} />
        }

        return (
            <CommonLayout className="vmiddle" pageId={this.props.pageId} >
                {
                    <Metadata />
                }
                <div className="auth-wrapper">
                    <div className="auth-container-block">
                        <div className="auth-content">
                            {
                                this.props.errorMessages && this.props.errorMessages.length > 0 &&
                                <div className="validation-summary-errors">
                                    <ul>{errors}</ul>
                                </div>
                            }
                            <div className="auth-form">
                                {this.props.children}
                            </div>

                            <div className="row">
                                {
                                    this.props.onCancelRedirect &&
                                    <div className="auth-action-button-block col-md-6">
                                        <button type="button" onClick={() => this.setState({ cancelRedirectTriggered: true })} disabled={this.props.loading}>
                                            {this.props.cancelButtonText}
                                        </button>
                                    </div>
                                }
                                <div className={`auth-action-button-block ${this.props.onCancelRedirect ? 'col-md-6' : 'col-md-12'}`}>
                                    <button type="button" onClick={this.handleAuthFormSubmit} disabled={this.props.loading}>
                                        {this.props.actionButtonText}
                                    </button>
                                </div>
                            </div>
                            {
                                this.props.onExternalLogin &&
                                <div className="auth-socials">
                                    <div className="auth-socials-separator">
                                        <div className="auth-socials-separator-text-wraper">
                                            <span>{i10n._VIEW_ACCOUNT_LOGIN_OR}</span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-6">
                                            <button type="button" className="mb-2 mb-md-4" onClick={() => { this.props.onExternalLogin("Facebook") }}>
                                                <AsyncSVG alt="Facebook" width="20" height="20" className="social-icon" src='/images/login-signup-icons-facebook.svg' />
                                                <span className="social-icon-label">Facebook</span>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button type="button" className="mb-2 mb-md-4" onClick={() => { this.props.onExternalLogin("Twitter") }}>
                                                <AsyncSVG alt="Twitter" width="20" height="20" className="social-icon" src='/images/login-signup-icons-twitter.svg' />
                                                <span className="social-icon-label">Twitter</span>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button type="button" className="mb-2 mb-md-4" onClick={() => { this.props.onExternalLogin("Google") }}>
                                                <AsyncSVG alt="Google" width="20" height="20" className="social-icon" src='/images/login-signup-icons-google.svg' />
                                                <span className="social-icon-label">Google</span>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button type="button" className="mb-2 mb-md-4" onClick={() => { this.props.onExternalLogin("Microsoft") }}>
                                                <AsyncSVG alt="Microsoft" width="20" height="20" className="social-icon" src='/images/login-signup-icons-microsoft.svg' />
                                                <span className="social-icon-label">Microsoft</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.props.authMessage &&
                                <div className="auth-message">
                                    {this.props.authMessage}
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </CommonLayout >);
    }
};

const mapState = ({ tool }) => {
    return {
        language: tool.lang
    };
}

const mapDispatch = () => {
    return {};
}

const AuthLayoutContainer = clear(connect(mapState, mapDispatch)(AuthLayout));
export default AuthLayoutContainer;