"use strict";


import i10n from '../common/i10n';
import { getLocalizedLink, ToolStatus } from '../common/components/tool';
import sitemap from '../common/sitemap';
import { fileFormats } from '../common/helpers/fileHelper';
import { getColumnList } from '../common/helpers/didYouKnowHelper';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkCrop,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkCrop,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkCrop } from '../pages/toolPages/cleanup-pdf/didYouKnowColumns'

import { 
    renderFirstColumn as renderFirstColumnDicom,
    renderSecondColumn as renderSecondColumnDicom,
    renderThirdColumn as renderThirdColumnDicom } from '../pages/toolPages/dicom-to-pdf/didYouKnowColumns'

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkDxf,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkDxf,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkDxf } from '../pages/toolPages/dxf-to-pdf/didYouKnowColumns'

import { 
    renderFirstColumn as renderFirstColumnBarcode,
    renderSecondColumn as renderSecondColumnBarcode } from '../pages/toolPages/barcode-recognition/didYouKnowColumns'

import { renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkConvertA } from '../pages/toolPages/convert-to-pdf-a/didYouKnowColumns'
import { renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkHyperCompress } from '../pages/toolPages/hyper-compress/didYouKnowColumns'
import { 
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkConvert, 
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkConvert } from '../pages/toolPages/convert/didYouKnowColumns'

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkEsign, 
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkEsign } from '../pages/toolPages/esign/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnExcelPdf, 
    renderSecondColumn as renderSecondColumnExcelPdf,
    renderThirdColumn as renderThirdColumnExcelPdf } from '../pages/toolPages/excel-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnJpgPdf, 
    renderSecondColumn as renderSecondColumnJpgPdf } from '../pages/toolPages/jpg-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkOcr, 
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkOcr } from '../pages/toolPages/ocr/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnOptimize, 
    renderSecondColumn as renderSecondColumnOptimize,
    renderThirdColumn as renderThirdColumnOptimize } from '../pages/toolPages/optimize-for-web/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkHtmlPdf, 
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkHtmlPdf,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkHtmlPdf } from '../pages/toolPages/html-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnPdfJpg,
    renderSecondColumn as renderSecondColumnPdfJpg } from '../pages/toolPages/pdf-to-jpeg/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnPdfTiff,
    renderSecondColumn as renderSecondColumnPdfTiff,
    renderThirdColumn as renderThirdColumnPdfTiff } from '../pages/toolPages/pdf-to-tiff/didYouKnowColumns';
    
import { 
    renderFirstColumn as renderFirstColumnValidator,
    renderSecondColumn as renderSecondColumnValidator,
    renderThirdColumn as renderThirdColumnValidator } from '../pages/toolPages/pdfa-validator/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkPngPdf ,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkPngPdf,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkPngPdf  } from '../pages/toolPages/png-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnPptxPdf ,
    renderSecondColumn as renderSecondColumnPptxPdf,
    renderThirdColumn as renderThirdColumnPptxPdf  } from '../pages/toolPages/pptx-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkProtect ,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkProtect,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkProtect  } from '../pages/toolPages/protect/didYouKnowColumns';
    
import { 
    renderFirstColumn as renderFirstColumnRedact,
    renderSecondColumn as renderSecondColumnRedact,
    renderThirdColumn as renderThirdColumnRedact } from '../pages/toolPages/redact-pdf/didYouKnowColumns';
import { 
    renderFirstColumn as renderFirstColumnRemoveText,
    renderSecondColumn as renderSecondColumnRemoveText,
    renderThirdColumn as  renderThirdColumnRemoveText } from '../pages/toolPages/remove-text/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnResize ,
    renderSecondColumn as renderSecondColumnResize } from '../pages/toolPages/resize/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnScan,
    renderSecondColumn as renderSecondColumnScan,
    renderThirdColumn as renderThirdColumnScan } from '../pages/toolPages/scan-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumn as renderFirstColumnSvgPdf,
    renderSecondColumn as renderSecondColumnSvgPdf } from '../pages/toolPages/svg-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkWordPdf,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkWordPdf,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkWordPdf } from '../pages/toolPages/word-to-pdf/didYouKnowColumns';

import { 
    renderFirstColumnWithAddedLink as renderFirstColumnWithAddedLinkGrayscale,
    renderSecondColumnWithAddedLink as renderSecondColumnWithAddedLinkGrayscale,
    renderThirdColumnWithAddedLink as renderThirdColumnWithAddedLinkGrayscale } from '../pages/toolPages/convert-pdf-to-grayscale/didYouKnowColumns';
import {
    renderFirstColumn as  renderFirstColumnWatermark,
    renderSecondColumn as renderSecondColumnWatermark,
    renderThirdColumn as renderThirdColumnWatermark
    } from '../pages/toolPages/watermark-pdf/didYouKnowColumns';
  
import {
    renderFirstColumn as  renderFirstColumnUnlock,
    renderSecondColumn as renderSecondColumnUnlock,
    renderThirdColumn as renderThirdColumnUnlock
    } from '../pages/toolPages/unlock/didYouKnowColumns';

    
  
import {
    renderFirstColumn  as renderFirstColumnEditPdfMeta,
    renderSecondColumn as renderSecondColumnEditPdfMeta,
    renderThirdColumn  as renderThirdColumnEditPdfMeta
} from '../pages/toolPages/edit-pdf-meta/didYouKnowColumns';

import {
    renderFirstColumn  as renderFirstColumnPdfToExcel,
    renderSecondColumn  as renderSecondColumnPdfToExcel
} from '../pages/toolPages/pdf-to-excel/didYouKnow';
        
var order = 1;

export const thumbnailMode = {
    document: "document",
    pages: "page",
    none: "none"
}

export const toolCategories = {
    none: 0,
    convert: 1,
    organize: 2,
    edit: 3,
    protect: 4,
    optimize: 5,
    extract: 6,
    popular: 7,
    other: 8
}

export const toolCategoriesLabel = {
    none: 0,
    get convert () { return i10n._VIEW_HOME_TOOL_CATEGORY_CONVERT },
    get organize() { return i10n._VIEW_HOME_TOOL_CATEGORY_ORGANIZE },
    get edit() { return i10n._VIEW_HOME_TOOL_CATEGORY_EDIT },
    get protect() { return i10n._VIEW_HOME_TOOL_CATEGORY_PROTECT },
    get optimize() { return i10n._VIEW_HOME_TOOL_CATEGORY_OPTIMIZE },
    get extract() { return i10n._VIEW_HOME_TOOL_CATEGORY_POPULAR },
    get popular() { return i10n._VIEW_HOME_TOOL_CATEGORY_POPULAR },
    get other() { return i10n._VIEW_HOME_TOOL_CATEGORY_OTHER }
}

function NextFillClassName() {
    return 'tool-' + (order++).toString();
}

export const blogArticles = {
    get default() {
        let link = "https://blog.avepdf.com/improve-website-help-users-with-pdf-tools/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/integrer-widget-avepdf-sur-votre-site-web/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/02/how-to-embed.png",
            title: i10n._BLOG_ARTICLE_DEFAULT_1
        }
        
    },
    get resize() {
        let link = "https://blog.avepdf.com/how-to-resize-pdf-online-modify-height-width-pages/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-redimensionner-pages-pdf-en-ligne/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/07/how-to-resize-a-PDF.png",
            title: i10n._BLOG_ARTICLE_HOW_TO_RESIZE
        }
    },
    get compress() {
        let link = "https://blog.avepdf.com/how-to-hyper-compress-a-pdf-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-hyper-compresser-pdf-en-ligne/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/03/how-to-hyper-compress.png",
            title: i10n._BLOG_ARTICLE_HYPERCOMPRESS
        }
    },
    get convert() {
        let link = "https://blog.avepdf.com/how-to-convert-pdf-document-to-pdfa-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-convertir-pdf-en-pdfa-en-ligne/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/08/pdf-is-durable-1.png",
            title: i10n._BLOG_ARTICLE_CONVERT_TO_PDFA
        }
    },
    get split() {
        let link = "https://blog.avepdf.com/how-to-split-pdf-document-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-diviser-pages-pdf-fichiers-independants/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/03/how-to-split-pdf-online.png",
            title: i10n._BLOG_ARTICLE_SPLIT_YOUR_PDF
        }
    },
    get secure(){
        let link = "https://blog.avepdf.com/three-free-tools-secure-pdf-files/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/trois-outils-gratuits-proteger-pdf/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/02/pdf-is-secure.png",
            title: i10n._BLOG_ARTICLE_FREE_TOOLS_FOR_SECURE
        }
    },
    get merge() {
        let link = "https://blog.avepdf.com/how-to-merge-documents-images-to-pdf-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-fusionner-documents-images-en-pdf-en-ligne/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/03/how-to-merge-pdf-online.png",
            title: i10n._BLOG_ARTICLE_MERGE_DOCUMENTS
        }
    },
    get combine() {
        let link = "https://blog.avepdf.com/how-to-combine-files-in-100-formats-to-pdf-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-combiner-fichiers-plus-100-formats-pdf/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/07/how-to-combine.png",
            title: i10n._BLOG_ARTICLE_HOW_TO_COMBINE_FILES
        }
    },
    get ocr() {
        let link = "https://blog.avepdf.com/how-to-ocr-files-in-100-formats-to-generate-a-searchable-pdf-online/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-oceriser-document-electronique-generer-pdf-interrogeable-en-ligne/ ";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/03/how-to-ocr-online.jpg",
            title: i10n._BLOG_ARTICLE_CREATEOCR_GENERATEPDF
        }
    },
    get redact(){
        let link = "https://blog.avepdf.com/how-to-redact-pdf-online-remove-sensitive-personal-data/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/comment-biffer-pdf-en-ligne-supprimer-donnees-personnelles-sensibles/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/08/how-to-redact-a-PDF.png",
            title: i10n._BLOG_ARTICLE_REDACT_A_PDF_ONLINE
        }
    },
    get redactStory(){
        let link = "https://blog.avepdf.com/epic-pdf-redaction-fails-horror-story/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/redaction-pdf-erreurs-a-ne-pas-reproduire/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2022/03/epic-redact-fail.png",
            title: i10n._BLOG_ARTICLE_REDACT_STORY_FAIL
        }
    },
    get pdfa4(){
        let link = "https://blog.avepdf.com/what-is-pdfa4/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/pdfa4-definition-utilisation/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2021/07/whypdfA4.png",
            title: i10n._BLOG_ARTICLE_WHY_PDFA_4
        }
    },
    get scan(){
        let link = "https://blog.avepdf.com/easy-online-scanning-workflow-with-avepdf/";
        switch(i10n.currentLang){
            case "fr": 
                link = "https://blog.avepdf.com/fr/numerisez-facilement-documents-en-ligne-avec-avepdf/";
                break;
        }
        return {
            link: link,
            image: "https://blog.avepdf.com/wp-content/uploads/2022/01/easy-online-scanning-workflow.png",
            title: i10n._BLOG_ARTICLE_SCAN
        }
    }
}

export const GdPictureAnchor = {
    get barcode(){
        return {
            title: "GdPicture Barcode Scanner SDK",
            link: "https://www.gdpicture.com/barcode-sdk/"
        }
    },
    get cad(){
        return {
            title: "GdPicture CAD SDK",
            link: "https://www.gdpicture.com/formats-sdk/cad/"
        }
    },
    get cleanup(){
        return {
            title: "GdPicture OCR Preprocessing SDK",
            link: "https://www.gdpicture.com/document-imaging-sdk/document-image-processing/"
        }
    },
    get dicom(){
        return {
            title: "GdPicture DICOM SDK",
            link: "https://www.gdpicture.com/formats-sdk/dicom/"
        }
    },
    get documentViewing(){
        return {
            title: "GdPicture Document Viewer SDK",
            link: "https://www.gdpicture.com/document-imaging-sdk/document-viewing/"
        }
    },
    get xlsx(){
        return {
            title: "GdPicture Excel SDK",
            link: "https://www.gdpicture.com/formats-sdk/xlsx/"
        }
    },
    get html(){
        return {
            title: "GdPicture HTML to PDF Library",
            link: "https://www.gdpicture.com/formats-sdk/html/"
        }
    },
    get imageProcessing(){
        return {
            title: "GdPicture Image Processing SDK",
            link: "https://www.gdpicture.com/document-imaging-sdk/image-processing/"
        }
    },
    get hyperCompress(){
        return {
            title: "GdPicture MRC Compression SDK",
            link: "https://www.gdpicture.com/ocr-sdk/hyper-compression/"
        }
    },
    get ocr(){
        return {
            title: "GdPicture OCR SDK",
            link: "https://www.gdpicture.com/ocr-sdk/"
        }
    },
    get xmp(){
        return {
            title: "GdPicture PDF Annotation SDK",
            link: "https://www.gdpicture.com/pdf-sdk/xmp-annotation/"
        }
    },
    get converter(){
        return {
            title: "GdPicture PDF Conversion SDK",
            link: "https://www.gdpicture.com/formats-sdk/document-converter/"
        }
    },
    get editing(){
        return {
            title: "GdPicture PDF Editor SDK",
            link: "https://www.gdpicture.com/pdf-sdk/editing/"
        }
    },
    get optimization(){
        return {
            title: "GdPicture PDF Optimization SDK",
            link: "https://www.gdpicture.com/pdf-sdk/pdf-optimization/"
        }
    },
    get redact(){
        return {
            title: "GdPicture PDF Redaction SDK",
            link: "https://www.gdpicture.com/pdf-sdk/pdf-redaction/"
        }
    },
    get pdf(){
        return {
            title: "GdPicture PDF SDK",
            link: "https://www.gdpicture.com/pdf-sdk/"
        }
    },
    get signature(){
        return {
            title: "GdPicture PDF Signature SDK",
            link: "https://www.gdpicture.com/pdf-sdk/pdf-signature/"
        }
    },
    get pdfa(){
        return {
            title: "GdPicture PDF/A Conversion SDK",
            link: "https://www.gdpicture.com/pdf-sdk/pdfa-conversion/"
        }
    },
    get powerpoint(){
        return {
            title: "GdPicture PowerPoint SDK",
            link: "https://www.gdpicture.com/formats-sdk/pptx/"
        }
    },
    get scan(){
        return {
            title: "GdPicture Scanning SDK",
            link: "https://www.gdpicture.com/scanning-sdk/"
        }
    },
    get svg(){
        return {
            title: "GdPicture SVG SDK",
            link: "https://www.gdpicture.com/formats-sdk/svg/"
        }
    },
    get tiff(){
        return {
            title: "GdPicture TIFF SDK",
            link: "https://www.gdpicture.com/formats-sdk/tiff/"
        }
    },
    get docx(){
        return {
            title: "GdPicture Word SDK",
            link: "https://www.gdpicture.com/formats-sdk/docx/"
        }
    },
    get tableExtraction(){
        return {
            title: "GdPicture Table Extraction SDK",
            link: "https://www.gdpicture.com/intelligent-document-processing/table-extraction/"
        }
    }
}

export const PSPDFKitAnchor = {
    get barcode(){
        return {
            title: "PSPDFKit GdPicture.NET Barcode Scanner",
            link: "https://pspdfkit.com/pdf-library/dotnet/#barcodes"
        }
    },
    get cad(){
        return {
            title: "PSPDFKit GdPicture.NET CAD",
            link: "https://pspdfkit.com/guides/dotnet/conversion/cad-to-pdf/"
        }
    },
    get cleanup(){
        return {
            title: "PSPDFKit GdPicture.NET OCR Preprocessing",
            link: "https://pspdfkit.com/guides/dotnet/ocr/preprocess/"
        }
    },
    get dicom(){
        return {
            title: "PSPDFKit GdPicture.NET DICOM",
            link: "https://pspdfkit.com/guides/dotnet/load-a-file/imaging/from-local-storage-dicom"
        }
    },
    get documentViewing(){
        return {
            title: "PSPDFKit GdPicture.NET Document Viewer",
            link: "https://pspdfkit.com/guides/dotnet/"
        }
    },
    get xlsx(){
        return {
            title: "PSPDFKit GdPicture.NET Excel",
            link: "https://pspdfkit.com/pdf-sdk/dotnet/pdf-to-excel/"
        }
    },
    get html(){
        return {
            title: "PSPDFKit GdPicture.NET HTML to PDF",
            link: "https://pspdfkit.com/guides/dotnet/conversion/html-to-pdf/"
        }
    },
    get imageProcessing(){
        return {
            title: "PSPDFKit GdPicture.NET Image Processing",
            link: "https://pspdfkit.com/pdf-library/dotnet/#image-processing"
        }
    },
    get hyperCompress(){
        return {
            title: "PSPDFKit GdPicture.NET Hyper-Compression",
            link: "https://pspdfkit.com/pdf-library/dotnet/#compression"
        }
    },
    get ocr(){
        return {
            title: "PSPDFKit GdPicture.NET OCR",
            link: "https://pspdfkit.com/pdf-library/dotnet/#ocr"
        }
    },
    get xmp(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Annotation",
            link: "https://pspdfkit.com/guides/dotnet/annotations/"
        }
    },
    get converter(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Conversion",
            link: "https://pspdfkit.com/guides/dotnet/conversion/"
        }
    },
    get editing(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Editor",
            link: "https://pspdfkit.com/guides/dotnet/editor/"
        }
    },
    get optimization(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Optimization",
            link: "https://pspdfkit.com/guides/dotnet/optimization/"
        }
    },
    get redact(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Redaction",
            link: "https://pspdfkit.com/guides/dotnet/redaction/"
        }
    },
    get pdf(){
        return {
            title: "PSPDFKit GdPicture.NET PDF",
            link: "https://pspdfkit.com/pdf-library/dotnet/"
        }
    },
    get signature(){
        return {
            title: "PSPDFKit GdPicture.NET PDF Signature",
            link: "https://pspdfkit.com/guides/dotnet/signatures/"
        }
    },
    get pdfa(){
        return {
            title: "PSPDFKit GdPicture.NET PDF/A Conversion",
            link: "https://pspdfkit.com/pdf-library/dotnet/#pdfa"
        }
    },
    get powerpoint(){
        return {
            title: "PSPDFKit GdPicture.NET PowerPoint",
            link: "https://pspdfkit.com/pdf-library/dotnet/pdf-to-office-sdk/"
        }
    },
    get scan(){
        return {
            title: "PSPDFKit GdPicture.NET Scanning",
            link: "https://pspdfkit.com/pdf-library/dotnet/#scanning"
        }
    },
    get svg(){
        return {
            title: "PSPDFKit GdPicture.NET SVG",
            link: "https://pspdfkit.com/guides/dotnet/conversion/svg-to-pdf"
        }
    },
    get tiff(){
        return {
            title: "PSPDFKit GdPicture.NET TIFF",
            link: "https://pspdfkit.com/guides/dotnet/conversion/tiff-to-pdf"
        }
    },
    get docx(){
        return {
            title: "PSPDFKit GdPicture.NET Word",
            link: "https://pspdfkit.com/pdf-library/dotnet/pdf-to-office-sdk/"
        }
    },
    get tableExtraction(){
        return {
            title: "PSPDFKit GdPicture.NET Table Extraction",
            link: "https://pspdfkit.com/pdf-sdk/dotnet/pdf-to-excel/"
        }
    }
}

export const globalPageId = "31BC5831-2F5E-4F10-B22A-222548C0244C";

const tools = {
    hyperCompress: {
        route: sitemap.VIEW_PDFMRC_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDFMRC_ROUTE),
        actionUrl: `tools/${sitemap.PDFMRC_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-hyper-compress.svg',
        title: () => i10n._VIEW_MRC_COMPRESSPDF_TITLE,
        subtitle: () => i10n._VIEW_MRC_COMPRESSPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'F2049139-0917-4D5B-B265-77CF1DC6012E',
        status: ToolStatus.Highlight,
        ratingIcon: '/images/hyper-compress-rating.png',
        howToUseLocalizationKeyPart: "MRC_COMPRESSPDF",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return { ext: window.AvePDFAppState.ImportExtensions.join(',') } },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_COMPRESSPDF_LOADING,
        category: [toolCategories.optimize, toolCategories.popular],
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.compress,
                blogArticles.scan
            ]
        },
        get didYouKnow() {
            const colorClass = tools.hyperCompress.fillClassName;
            const columns = getColumnList(tools.hyperCompress.howToUseLocalizationKeyPart);
    
            const column1 = { title: columns[0].title, body: renderFirstColumnWithAddedLinkHyperCompress(columns[0].text), class: colorClass };
            const column2 = { title: columns[1].title, body: i10n._VIEW_MRC_COMPRESSPDF_DIDYOUKNOW_COLUMN_2_DESC_ALL, class: colorClass };
            const column3 = { title: columns[2].title, body: i10n._VIEW_MRC_COMPRESSPDF_DIDYOUKNOW_COLUMN_3_DESC_ALL, class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.hyperCompress,
        merchandise2: PSPDFKitAnchor.hyperCompress
    },
    esign: {
        route: sitemap.VIEW_ESIGN_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_ESIGN_PDF_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-eSign.svg',
        title: () => i10n._VIEW_ESIGNPDF_TITLE,
        subtitle: () => i10n._VIEW_ESIGNPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '5DA4A0CB-86DE-4625-BA10-CD9AB61460B8',
        status: ToolStatus.Highlight,
        ratingIcon: '/images/esign-pdf-rating.png',
        howToUseLocalizationKeyPart: "ESIGNPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_ESIGNPDF_LOADING,
        category: [toolCategories.protect, toolCategories.popular],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.secure
            ]
        },
        get didYouKnow() {
            const colorClass = tools.esign.fillClassName;

            const column1 = { title: i10n._VIEW_ESIGN_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkEsign(), class: colorClass };
            const column2 = { title: i10n._VIEW_ESIGN_DIDYOUKNOW_COLUMN_2_TITLE, body: i10n._VIEW_ESIGN_DIDYOUKNOW_COLUMN_2_DESC_ALL, class: colorClass };
            const column3 = { title: i10n._VIEW_ESIGN_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkEsign(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.signature,
        merchandise2: PSPDFKitAnchor.signature
    },
    convert: {
        route: sitemap.VIEW_CONVERT_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_CONVERT_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-convert.svg',
        title: () => i10n._VIEW_CONVERTTOPDF_TITLE,
        subtitle: () => i10n._VIEW_CONVERTTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '1D2091A0-0C9E-46CD-B8A0-E7D22EEB2CD4',
        status: ToolStatus.Highlight,
        ratingIcon: '/images/convert-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "CONVERTTOPDF",
        uploadFileIcon: '/images/white-page-upload-convert-to-pdf.svg',
        get uploadFileTypes () { return { ext: window.AvePDFAppState.ImportExtensions.filter(e => e.toLowerCase() !== ".pdf").join(',') } },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert, toolCategories.popular],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.convert
            ]
        },
        get didYouKnow() {
            const colorClass = tools.convert.fillClassName;
            const column1 = { title: i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_1_DESC_ALL, class: colorClass };
            const column2 = { title: i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkConvert(), class: colorClass };
            const column3 = { title: i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkConvert(colorClass), class: colorClass, isReactComponent: true };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    combine: {
        route: sitemap.VIEW_COMBINE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_COMBINE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.COMBINE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-combine.svg',
        title: () => i10n._VIEW_COMBINE_TITLE,
        subtitle: () => i10n._VIEW_COMBINE_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'B28C6EEF-8EC6-48FC-ADFE-C0EFAB1424D9',
        status: ToolStatus.None,
        ratingIcon: '/images/combine-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "COMBINE",
        uploadFileIcon: '/images/white-page-upload-generic-files2.svg',
        get uploadFileTypes () { return {  ext:  window.AvePDFAppState.ImportExtensions.join(',') + ",.zip" } }, 
        uploadMultipleFiles: true,
        loadingSubTitle: () => i10n._VIEW_COMBINE_LOADING,
        thumbnail: {
            mode: thumbnailMode.document,
            width: 250,
            height: 250
        },
        category: [toolCategories.convert, toolCategories.popular],
        get blogArticles() {
            return [
                blogArticles.merge,
                blogArticles.combine
            ]
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    split: {
        route: sitemap.VIEW_SPLIT_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_SPLIT_PDF_ROUTE),
        actionUrl: `tools/${sitemap.SPLIT_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-split.svg',
        title: () => i10n._VIEW_SPLITPDF_TITLE,
        subtitle: () => i10n._VIEW_SPLITPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '6DD1A8E1-34D5-48EA-A952-F94025CBF044',
        status: ToolStatus.None,
        ratingIcon: '/images/split-pdf-rating.png',
        howToUseLocalizationKeyPart: "SPLITPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_SPLITPDF_LOADING,
        thumbnail: {
            mode: thumbnailMode.pages,
            width: 250,
            height: 250
        },
        category: [toolCategories.organize],
        get blogArticles() {
            return [
                blogArticles.split
            ]
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    merge: {
        route: sitemap.VIEW_MERGE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_MERGE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.MERGE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-merge.svg',
        title: () => i10n._VIEW_MERGEPDF_TITLE,
        subtitle: () => i10n._VIEW_MERGEPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '9F958169-E724-4002-AE8D-033FAAF803CE',
        status: ToolStatus.None,
        ratingIcon: '/images/merge-pdf-rating.png',
        howToUseLocalizationKeyPart: "MERGEPDF",
        uploadFileIcon: '/images/white-page-upload-generic-files3.svg',
        get uploadFileTypes () { return {  ext: `${fileFormats.pdf.ext},${fileFormats.zip.ext}` } },
        uploadMultipleFiles: true,
        uploadApiPreloadSection: "NONE",
        loadingSubTitle: () => i10n._VIEW_MERGEPDF_LOADING,
        thumbnail: {
            mode: thumbnailMode.document,
            width: 250,
            height: 250
        },
        category: [toolCategories.organize],
        get blogArticles() {
            return [
                blogArticles.merge,
                blogArticles.combine
            ]
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    organize: {
        route: sitemap.VIEW_ORGANIZE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_ORGANIZE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.ORGANIZE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-organize.svg',
        title: () => i10n._VIEW_ORGANIZEPDF_TITLE,
        subtitle: () => i10n._VIEW_ORGANIZEPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '341FA6B0-CE2A-4FEC-822C-9537FF7368C3',
        status: ToolStatus.None,
        ratingIcon: '/images/organize-pdf-rating.png',
        howToUseLocalizationKeyPart: "ORGANIZEPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_ORGANIZEPDF_LOADING,
        thumbnail: {
            mode: thumbnailMode.pages,
            width: 250,
            height: 250
        },
        category: [toolCategories.organize, toolCategories.popular],
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    reversePdf: {
        route: sitemap.VIEW_REVERSE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REVERSE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.REVERSE_PDF_ACTION_ROUTE}`,
        image: '/images/54-white-avepdf-svg-reorder-pdf.svg',
        title: () => i10n._VIEW_REVERSE_PDF_TITLE,
        subtitle: () => i10n._VIEW_REVERSE_PDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '83E6D17B-ADFA-4FCB-9B88-D676166A55A6',
        status: ToolStatus.None,
        ratingIcon: '/images/reorder-pdf-rating.png',
        howToUseLocalizationKeyPart: "REVERSE_PDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_REVERSE_PDF_LOADING,
        category: [toolCategories.organize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    removePages: {
        route: sitemap.VIEW_REMOVE_PDF_PAGES_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REMOVE_PDF_PAGES_ROUTE),
        actionUrl: `tools/${sitemap.REMOVE_PDF_PAGES_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-remove-pages.svg',
        title: () => i10n._VIEW_DELETEPAGES_TITLE,
        subtitle: () => i10n._VIEW_DELETEPAGES_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '001CC55C-00D2-4140-93B8-455035E600BE',
        status: ToolStatus.None,
        ratingIcon: '/images/remove-pages-rating.png',
        howToUseLocalizationKeyPart: "DELETEPAGES",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_DELETEPAGES_LOADING,
        showFileDetail: false,
        thumbnail: {
            mode: thumbnailMode.pages,
            width: 250,
            height: 250
        },
        category: [toolCategories.organize],
        get blogArticles() { return [ blogArticles.split ] },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    rotate: {
        route: sitemap.VIEW_ROTATE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_ROTATE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.ROTATE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-rotate.svg',
        title: () => i10n._VIEW_ROTATEPDF_TITLE,
        subtitle: () => i10n._VIEW_ROTATEPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'AF5785CF-9E0E-4A0B-B335-F315E7CA0358',
        status: ToolStatus.None,
        ratingIcon: '/images/rotate-pdf-rating.png',
        howToUseLocalizationKeyPart: "ROTATEPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: false,
        loadingSubTitle: () => i10n._VIEW_ROTATEPDF_LOADING,
        thumbnail: {
            mode: thumbnailMode.pages,
            width: 250,
            height: 250
        },
        category: [toolCategories.organize],
        merchandise: GdPictureAnchor.editing,
        merchandise: GdPictureAnchor.editing
    },
    convertToOnePagePdf: {
        route: sitemap.VIEW_CONVERT_TO_ONE_PAGE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_CONVERT_TO_ONE_PAGE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_ONE_PAGE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-convert-to-one-page.svg',
        title: () => i10n._VIEW_CONVERTTOONEPAGE_TITLE,
        subtitle: () => i10n._VIEW_CONVERTTOONEPAGE_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '4BAD7800-AE6F-4A75-B8FC-4ABD5644AD8A',
        status: ToolStatus.None,
        ratingIcon: '/images/convert-to-one-page-rating.png',
        howToUseLocalizationKeyPart: "CONVERTTOONEPAGE",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOONEPAGE_LOADING,
        category: [toolCategories.organize],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise: GdPictureAnchor.editing
    },
    protect: {
        route: sitemap.VIEW_LOCK_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_LOCK_PDF_ROUTE),
        actionUrl: `tools/${sitemap.LOCK_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-protect.svg',
        title: () => i10n._VIEW_LOCKPDF_TITLE,
        subtitle: () => i10n._VIEW_LOCKPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '61A40D10-9548-4470-81DD-60FD4C439868',
        status: ToolStatus.None,
        ratingIcon: '/images/lock-pdf-rating.png',
        howToUseLocalizationKeyPart: "LOCKPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_LOCKPDF_LOADING,
        category: [toolCategories.protect],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.secure,
                blogArticles.redact

            ]
        },
        get didYouKnow() {
            const colorClass = tools.protect.fillClassName;
            const column1 = { title: i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkProtect(), class: colorClass };
            const column2 = { title: i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkProtect(), class: colorClass };
            const column3 = { title: i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkProtect(), class: colorClass };
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.pdf,
        merchandise2: PSPDFKitAnchor.pdf
    },
    unlock: {
        route: sitemap.VIEW_UNLOCK_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_UNLOCK_PDF_ROUTE),
        actionUrl: `tools/${sitemap.UNLOCK_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-unlock.svg',
        title: () => i10n._VIEW_UNLOCKPDF_TITLE,
        subtitle: () => i10n._VIEW_UNLOCKPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '7E5312F1-6EB2-4613-B4D6-31F7DF205A69',
        status: ToolStatus.None,
        ratingIcon: '/images/unlock-pdf-rating.png',
        howToUseLocalizationKeyPart: "UNLOCKPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        toolAcceptsPasswordProtectedFiles: true,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_UNLOCKPDF_LOADING,
        category: [toolCategories.protect],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.secure
            ]
        },
        get didYouKnow() {
            const colorClass = tools.unlock.fillClassName;
            const column1 = { title: i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnUnlock(), class: colorClass };
            const column2 = { title: i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnUnlock(), class: colorClass };
            const column3 = { title: i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnUnlock(), class: colorClass };
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.pdf,
        merchandise2: PSPDFKitAnchor.pdf
    },
    redactPdf: {
        route: sitemap.VIEW_REDACT_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REDACT_PDF_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-redact-pdf.svg',
        title: () => i10n._VIEW_REDACTPDF_TITLE,
        subtitle: () => i10n._VIEW_REDACTPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '1C11010D-4DC3-4610-BCE8-4E51EEB6D75B',
        status: ToolStatus.None,
        ratingIcon: '/images/redact-pdf-rating.png',
        howToUseLocalizationKeyPart: "REDACTPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_REDACTPDF_LOADING,
        category: [toolCategories.protect],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.secure,
                blogArticles.redact,
                blogArticles.redactStory
            ]
        },
        get didYouKnow() {
            const colorClass = tools.redactPdf.fillClassName;

            const column1 = { title: i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnRedact(), class: colorClass };
            const column2 = { title: i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnRedact(), class: colorClass };
            const column3 = { title: i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnRedact(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.redact,
        merchandise2: PSPDFKitAnchor.redact
    },
    convertA: {
        route: sitemap.VIEW_CONVERT_TO_PDF_A_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_CONVERT_TO_PDF_A_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_A_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-convert-to-pdf-a.svg',
        title: () => i10n._VIEW_CONVERTTOPDFA_TITLE,
        subtitle: () => i10n._VIEW_CONVERTTOPDFA_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '482A099B-1615-4E75-84A3-91D0AE8D2576',
        status: ToolStatus.None,
        ratingIcon: '/images/convert-to-pdfa-rating.png',
        howToUseLocalizationKeyPart: "CONVERTTOPDFA",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDFA_LOADING,
        category: [toolCategories.convert, toolCategories.popular],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.convert,
                blogArticles.pdfa4,
                blogArticles.scan
            ]
        },
        get didYouKnow() {
            const colorClass = tools.convertA.fillClassName;
            const columns = getColumnList(tools.convertA.howToUseLocalizationKeyPart);
    
            const column1 = {
                title: columns[0].title,
                body: renderFirstColumnWithAddedLinkConvertA(columns[0].text),
                class: colorClass
            };
            const column2 = { title: columns[1].title, body: i10n._VIEW_CONVERTTOPDFA_DIDYOUKNOW_COLUMN_2_DESC_ALL, class: colorClass };
            const column3 = { title: columns[2].title, body: i10n._VIEW_CONVERTTOPDFA_DIDYOUKNOW_COLUMN_3_DESC_ALL, class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.pdfa,
        merchandise2: PSPDFKitAnchor.pdfa
    },
    validationA: {
        route: sitemap.VIEW_DOCUMENT_PDFA_VALIDATOR_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_DOCUMENT_PDFA_VALIDATOR_ROUTE),
        actionUrl: `tools/${sitemap.PDFA_VALIDATOR_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-a-validation.svg',
        title: () => i10n._VIEW_PDFA_VALIDATOR_TITLE,
        subtitle: () => i10n._VIEW_PDFA_VALIDATOR_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '4255DB28-D0EE-4D9B-9DB3-3A95C47ECEAE',
        status: ToolStatus.None,
        ratingIcon: '/images/validate-pdfa-rating.png',
        howToUseLocalizationKeyPart: "PDFA_VALIDATOR",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_PDFA_VALIDATOR_LOADING,
        category: [toolCategories.optimize, toolCategories.popular],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.pdfa4,
                blogArticles.scan
            ]
        },
        get didYouKnow() {
            const colorClass = tools.validationA.fillClassName;
            const column1 = { title: i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnValidator(), class: colorClass };
            const column2 = { title: i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnValidator(), class: colorClass };
            const column3 = { title: i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnValidator(), class: colorClass };
    
            return [ column1, column2, column3 ];
        },
        merchandise: GdPictureAnchor.pdfa,
        merchandise2: PSPDFKitAnchor.pdfa
    },
    pdfRepair: {
        route: sitemap.VIEW_PDF_REPAIR_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_REPAIR_ROUTE),
        actionUrl: `tools/${sitemap.PDF_REPAIR_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-repair-pdf.svg',
        title: () => i10n._VIEW_PDFREPAIR_TITLE,
        subtitle: () => i10n._VIEW_PDFREPAIR_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'F51F59FA-6892-4C48-AF2C-3992B272E63F',
        status: ToolStatus.None,
        ratingIcon: '/images/repair-pdf-rating.png',
        howToUseLocalizationKeyPart: "PDFREPAIR",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_PDFREPAIR_LOADING,
        category: [toolCategories.optimize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.optimization,
        merchandise2: PSPDFKitAnchor.optimization
    },
    edit: {
        route: sitemap.VIEW_PDF_EDIT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_EDIT_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-edit-pdf.svg',
        title: () => i10n._VIEW_EDITPDF_TITLE,
        subtitle: () => i10n._VIEW_EDITPDF_SUBTITLE, //set correct during implementation
        fillClassName: NextFillClassName(),
        id: '04A4B446-B674-421F-8048-773E36563953',
        status: ToolStatus.None,
        ratingIcon: '/images/edit-pdf-rating.png',
        howToUseLocalizationKeyPart: "EDITPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_EDITPDF_LOADING_SUBTITLE,
        category: [toolCategories.edit, toolCategories.popular],
        showFileDetail: false,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.resize
            ]
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    ocr: {
        route: sitemap.VIEW_OCR_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_OCR_ROUTE),
        actionUrl: `tools/${sitemap.OCR_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-ocr.svg',
        title: () => i10n._VIEW_OCRPDF_TITLE,
        subtitle: () => i10n._VIEW_OCRPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '3F1C9D65-73A4-45D0-9776-4114ABDF9CD9',
        status: ToolStatus.None,
        ratingIcon: '/images/ocr-pdf-rating.png',
        howToUseLocalizationKeyPart: "OCRPDF",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return {  ext: window.AvePDFAppState.ImportExtensions.filter(e => e.toLowerCase() !== ".txt").join(',') } },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_OCRPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.optimize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.ocr
            ]
        },
        get didYouKnow() {
            const colorClass = tools.ocr.fillClassName;

            const column1 = { title: i10n._VIEW_OCR_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkOcr(), class: colorClass };
            const column2 = { title: i10n._VIEW_OCR_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkOcr(), class: colorClass };
            const column3 = { title: i10n._VIEW_OCR_DIDYOUKNOW_COLUMN_3_TITLE, body: i10n._VIEW_OCR_DIDYOUKNOW_COLUMN_3_ALL, class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.ocr,
        merchandise2: PSPDFKitAnchor.ocr
    },
    formFieldsFilling: {
        route: sitemap.VIEW_FILL_OUT_PDF_FORMS_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_FILL_OUT_PDF_FORMS_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-form-fields.svg',
        title: () => i10n._VIEW_FORMFIELDSFILLING_TITLE,
        subtitle: () => i10n._VIEW_FORMFIELDSFILLING_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'E6007E4E-D145-4438-AF03-9EE2BC63E7C6',
        status: ToolStatus.None,
        ratingIcon: '/images/fill-out-pdf-form-rating.png',
        howToUseLocalizationKeyPart: "FORMFIELDSFILLING",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_FORMFIELDSFILLING_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    flattenPdf: {
        route: sitemap.VIEW_FLATTEN_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_FLATTEN_PDF_ROUTE),
        actionUrl: `tools/${sitemap.FLATTEN_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-flatten.svg',
        title: () => i10n._VIEW_FLATTENPDF_TITLE,
        subtitle: () => i10n._VIEW_FLATTENPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'E510F8C4-B377-413E-8F1B-8681A36D5718',
        status: ToolStatus.None,
        ratingIcon: '/images/flaten-pdf-rating.png',
        howToUseLocalizationKeyPart: "FLATTENPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_FLATTENPDF_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    cropPdf: {
        route: sitemap.VIEW_CROP_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_CROP_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CROP_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-crop.svg',
        title: () => i10n._VIEW_CROPPDF_TITLE,
        subtitle: () => i10n._VIEW_CROPPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'A6DF5A0B-8BC0-4516-A792-F95D1C5D17FE',
        status: ToolStatus.None,
        ratingIcon: '/images/crop-pdf-rating.png',
        howToUseLocalizationKeyPart: "CROPPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CROPPDF_LOADING,
        category: [toolCategories.edit],
        showFileDetail: false,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() { return [ blogArticles.resize ] },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    resizePdf: {
        route: sitemap.VIEW_RESIZE_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_RESIZE_PDF_ROUTE),
        actionUrl: `tools/${sitemap.RESIZE_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-resize.svg',
        title: () => i10n._VIEW_RESIZEPDF_TITLE,
        subtitle: () => i10n._VIEW_RESIZEPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'FFDF430B-8220-4A26-42A2-4242EFFEE17F2',
        status: ToolStatus.None,
        ratingIcon: '/images/resize-pdf-rating.png',
        howToUseLocalizationKeyPart: "RESIZEPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_RESIZEPDF_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.document
        },
        get blogArticles() {
            return [
                blogArticles.resize
            ]
        },
        get didYouKnow() {
            const colorClass = tools.resizePdf.fillClassName;

            const column1 = { title: i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnResize(), class: colorClass };
            const column2 = { title: i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnResize(), class: colorClass };
    
            return [column1, column2 ];
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    numbers: {
        route: sitemap.VIEW_PAGING_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PAGING_PDF_ROUTE),
        actionUrl: `tools/${sitemap.PAGING_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-page-numbers.svg',
        title: () => i10n._VIEW_PAGENUMBERS_TITLE,
        subtitle: () => i10n._VIEW_PAGENUMBERS_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '6E94C10A-0408-490B-9320-868E3BB5030B',
        status: ToolStatus.None,
        ratingIcon: '/images/page-numbers-rating.png',
        howToUseLocalizationKeyPart: "PAGENUMBERS",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_PAGENUMBERS_LOADING,
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        category: [toolCategories.organize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    compress: {
        route: sitemap.VIEW_COMPRESS_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_COMPRESS_PDF_ROUTE),
        actionUrl: `tools/${sitemap.COMPRESS_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-compress.svg',
        title: () => i10n._VIEW_COMPRESSPDF_TITLE,
        subtitle: () => i10n._VIEW_COMPRESSPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '6DDA9076-859F-4E07-803A-B27A5549EA0B',
        status: ToolStatus.None,
        ratingIcon: '/images/compress-pdf-rating.png',
        howToUseLocalizationKeyPart: "COMPRESSPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_COMPRESSPDF_LOADING,
        category: [toolCategories.optimize],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get blogArticles() {
            return [
                blogArticles.compress
            ]
        },
        merchandise: GdPictureAnchor.hyperCompress,
        merchandise2: PSPDFKitAnchor.optimization
    },
    clean: {
        route: sitemap.VIEW_CLEANUP_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_CLEANUP_PDF_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-clean-pdf.svg',
        title: () => i10n._VIEW_CLEANUPPDF_TITLE,
        subtitle: () => i10n._VIEW_CLEANUPPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '3350560F-69EE-470C-999D-50F05C15C67A',
        status: ToolStatus.None,
        ratingIcon: '/images/clean-up-pdf-rating.png',
        howToUseLocalizationKeyPart: "CLEANUPPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_SCANNINGTOPDF_LOADING,
        category: [toolCategories.edit],
        showFileDetail: false,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.clean.fillClassName;

            const column1 = { title: i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkCrop(), class: colorClass };
            const column2 = { title: i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkCrop(), class: colorClass };
            const column3 = { title: i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkCrop(), class: colorClass };
    
            return [column1, column2, column3];
        },
        get blogArticles() {
            return [
                blogArticles.scan
            ]
        },
        merchandise: GdPictureAnchor.cleanup,
        merchandise2: PSPDFKitAnchor.cleanup
    },
    watermark: {
        route: sitemap.VIEW_WATERMARK_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_WATERMARK_PDF_ROUTE),
        actionUrl: `tools/${sitemap.WATERMARK_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-watermark.svg',
        title: () => i10n._VIEW_WATERMARKPDF_TITLE,
        subtitle: () => i10n._VIEW_WATERMARKPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '82B8A1B2-4F61-4F05-8962-935DFB0EEB45',
        status: ToolStatus.None,
        ratingIcon: '/images/watermark-pdf-rating.png',
        howToUseLocalizationKeyPart: "WATERMARKPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_WATERMARKPDF_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.document,
            width: 640,
            height: 920
        },
        get didYouKnow() {
            const colorClass = tools.watermark.fillClassName;

            const column1 = { title: i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWatermark(), class: colorClass };
            const column2 = { title: i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWatermark(), class: colorClass };
            const column3 = { title: i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWatermark(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.pdf,
        merchandise2: PSPDFKitAnchor.pdf
    },
    scanningToPdf: {
        route: sitemap.VIEW_SCAN_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_SCAN_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-scanning-to-pdf.svg',
        title: () => i10n._VIEW_SCANNINGTOPDF_TITLE,
        subtitle: () => i10n._VIEW_SCANNINGTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'EFCA7FE2-3DC3-4011-8F77-DC9BAF7838DD',
        status: ToolStatus.None,
        ratingIcon: '/images/scan-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "SCANNINGTOPDF",
        uploadFileIcon: null,
        get uploadFileTypes () { return  null },
        uploadMultipleFiles: true,
        loadingSubTitle: () => i10n._VIEW_SCANNINGTOPDF_LOADING,
        category: [toolCategories.convert, toolCategories.popular],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.scanningToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnScan(), class: colorClass };
            const column2 = { title: i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnScan(), class: colorClass };
            const column3 = { title: i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnScan(), class: colorClass };
    
            return [column1, column2, column3];
        },
        get blogArticles() {
            return [
                blogArticles.scan
            ]
        },
        merchandise: GdPictureAnchor.scan,
        merchandise2: PSPDFKitAnchor.scan
    },
    pdfToTiff: {
        route: sitemap.VIEW_PDF_TO_TIFF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_TIFF_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_TIFF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-tiff.svg',
        title: () => i10n._VIEW_PDFTOTIFF_TITLE,
        subtitle: () => i10n._VIEW_PDFTOTIFF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '18C00E46-A4B9-4C7C-904E-C8960B873252',
        status: ToolStatus.None,
        ratingIcon: '/images/pdf-to-tiff-rating.png',
        howToUseLocalizationKeyPart: "PDFTOTIFF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.pdfToTiff.fillClassName;

            const column1 = { title: i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnPdfTiff(), class: colorClass };
            const column2 = { title: i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnPdfTiff(), class: colorClass };
            const column3 = { title: i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnPdfTiff(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.tiff,
        merchandise2: PSPDFKitAnchor.tiff
    },
    pdfToJpg: {
        route: sitemap.VIEW_PDF_TO_JPEG_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_JPEG_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_JPEG_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-jpg.svg',
        title: () => i10n._VIEW_PDFTOJPG_TITLE,
        subtitle: () => i10n._VIEW_PDFTOJPG_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '27D73DD6-670A-41D6-859F-EB8ABB3FAC8E',
        status: ToolStatus.None,
        ratingIcon: '/images/pdf-to-jpg-rating.png',
        howToUseLocalizationKeyPart: "PDFTOJPG",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.pdfToJpg.fillClassName;
            const column1 = { title: i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnPdfJpg(), class: colorClass };
            const column2 = { title: i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnPdfJpg(), class: colorClass };
            return [column1, column2 ];
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    pdfToPng: {
        route: sitemap.VIEW_PDF_TO_PNG_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_PNG_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_PNG_ACTION_ROUTE}`,
        image: '/images/52-white-pdf-to-png.svg',
        title: () => i10n._VIEW_PDFTOPNG_TITLE,
        subtitle: () => i10n._VIEW_PDFTOPNG_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'D07CC0FE-38A4-4070-A752-3817016522B5',
        status: ToolStatus.None,
        ratingIcon: '/images/pdf-to-png-rating.png',
        howToUseLocalizationKeyPart: "PDFTOPNG",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    wordToPdf: {
        route: sitemap.VIEW_WORD_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_WORD_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-word-to-pdf.svg',
        title: () => i10n._VIEW_WORDTOPDF_TITLE,
        subtitle: () => i10n._VIEW_WORDTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '23351101-4169-44E5-B018-CC451A1FC356',
        status: ToolStatus.None,
        ratingIcon: '/images/word-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "WORDTOPDF",
        uploadFileIcon: '/images/white-page-upload-word.svg',
        get uploadFileTypes () { return  fileFormats.word },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.wordToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkWordPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkWordPdf(), class: colorClass };
            const column3 = { title: i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkWordPdf(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.docx,
        merchandise2: PSPDFKitAnchor.docx
    },
    imageToExcel: {
        route: sitemap.VIEW_IMAGE_TO_EXCEL_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_IMAGE_TO_EXCEL_ROUTE),
        actionUrl: `tools/${sitemap.IMAGE_TO_EXCEL_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-image-to-excel.svg',
        title: () => i10n._VIEW_IMAGETOEXCEL_TITLE,
        subtitle: () => i10n._VIEW_IMAGETOEXCEL_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '82B8A1B2-4F61-4F05-8962-935DFB0EEB46',
        status: ToolStatus.New,
        ratingIcon: '', //'/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "IMAGETOEXCEL",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        uploadFileTypes: fileFormats.imgBrowserSupported,
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadAvoidPreload: false,
        loadingSubTitle: () =>i10n._VIEW_IMAGETOEXCEL_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.tableExtraction,
        merchandise2: PSPDFKitAnchor.tableExtraction
    },

    pdfToWord: {
        route: sitemap.VIEW_PDF_TO_WORD_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_WORD_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_WORD_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-word.svg',
        title: () => i10n._VIEW_PDFTOWORD_TITLE,
        subtitle: () => i10n._VIEW_PDFTOWORD_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '391903DE-B041-447C-8105-0CD009D05C63',
        status: ToolStatus.New,
        ratingIcon: '', //'/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "PDFTOWORD",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return { ext: fileFormats.pdf.ext } },
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadAvoidPreload: false,
        loadingSubTitle: () =>i10n._VIEW_PDFTOWORD_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        //merchandise: GdPictureAnchor.
    },
    pdfToExcel: {
        route: sitemap.VIEW_PDF_TO_EXCEL_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_EXCEL_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_EXCEL_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-excel.svg',
        title: () => i10n._VIEW_PDFTOEXCEL_TITLE,
        subtitle: () => i10n._VIEW_PDFTOEXCEL_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '613162A2-8888-46D2-B15A-92EA8F752EF1',
        status: ToolStatus.New,
        ratingIcon: '', //'/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "PDFTOEXCEL",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return { ext: fileFormats.pdf.ext } },
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadAvoidPreload: false,
        loadingSubTitle: () =>i10n._VIEW_PDFTOEXCEL_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.pdfToExcel.fillClassName;

            const column1 = { title: i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnPdfToExcel(), class: colorClass };
            const column2 = { title: i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnPdfToExcel(), class: colorClass };
    
            return [column1, column2];
        },
        //merchandise: GdPictureAnchor.tableExtraction
    },
    pdfToPowerPoint: {
        route: sitemap.VIEW_PDF_TO_POWERPOINT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_POWERPOINT_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_POWERPOINT_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-powerpoint.svg',
        title: () => i10n._VIEW_PDFTOPOWERPOINT_TITLE,
        subtitle: () => i10n._VIEW_PDFTOPOWERPOINT_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'A2316BE6-FD93-4375-9121-4EAF79CC6FD6',
        status: ToolStatus.New,
        ratingIcon: '', //'/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "PDFTOPOWERPOINT",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return { ext: fileFormats.pdf.ext } },
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadAvoidPreload: false,
        loadingSubTitle: () =>i10n._VIEW_PDFTOPOWERPOINT_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        //merchandise: GdPictureAnchor.tableExtraction
    },

    excelToPdf: {
        route: sitemap.VIEW_EXCEL_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_EXCEL_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-excel.svg',
        title: () => i10n._VIEW_EXCELTOPDF_TITLE,
        subtitle: () => i10n._VIEW_EXCELTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '0552EB15-B74C-4B5D-9423-AA2CE2805485',
        status: ToolStatus.None,
        ratingIcon: '/images/excel-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "EXCELTOPDF",
        uploadFileIcon: '/images/white-page-upload-excel.svg',
        get uploadFileTypes () { return  fileFormats.excel },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.excelToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnExcelPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnExcelPdf(), class: colorClass };
            const column3 = { title: i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnExcelPdf(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.xlsx,
        merchandise2: PSPDFKitAnchor.xlsx
    },
    pptxToPdf: {
        route: sitemap.VIEW_PPTX_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PPTX_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pptx-to-pdf.svg',
        title: () => i10n._VIEW_PPTX_TO_PDF_TITLE,
        subtitle: () => i10n._VIEW_PPTX_TO_PDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '4E88896E-E53E-4E37-8143-1E2E20AFC036',
        status: ToolStatus.None,
        ratingIcon: '/images/pptx-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "PPTX_TO_PDF",
        uploadFileIcon: '/images/white-page-upload-pptx.svg',
        get uploadFileTypes () { return  fileFormats.powerpoint },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_PPTX_TO_PDF_LOADING_SUBTITLE,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.pptxToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnPptxPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnPptxPdf(), class: colorClass };
            const column3 = { title: i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnPptxPdf(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.powerpoint,
        merchandise2: PSPDFKitAnchor.powerpoint
    },
    tiffToPdf: {
        route: sitemap.VIEW_TIFF_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_TIFF_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-tiff-to-pdf.svg',
        title: () => i10n._VIEW_TIFFTOPDF_TITLE,
        subtitle: () => i10n._VIEW_TIFFTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '72F047FB-DF67-422A-BE4D-7EDA3DF5BE33',
        status: ToolStatus.None,
        ratingIcon: '/images/tiff-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "TIFFTOPDF",
        uploadFileIcon: '/images/white-page-upload-tiff.svg',
        get uploadFileTypes () { return  fileFormats.tiff },
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadApiPreloadSection: "NONE",
        uploadAvoidPreload: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.tiff,
        merchandise2: PSPDFKitAnchor.tiff
    },
    jpgToPdf: {
        route: sitemap.VIEW_JPG_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_JPG_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-jpg-to-pdf.svg',
        title: () => i10n._VIEW_JPGTOPDF_TITLE,
        subtitle: () => i10n._VIEW_JPGTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'BB357FD3-4EFC-40E8-AEF7-45657F0CFA37',
        status: ToolStatus.None,
        ratingIcon: '/images/jpg-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "JPGTOPDF",
        uploadFileIcon: '/images/white-page-upload-jpg.svg',
        get uploadFileTypes () { return  fileFormats.jpg },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.jpgToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnJpgPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnJpgPdf(), class: colorClass };
    
            return [column1, column2];
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    pngToPdf: {
        route: sitemap.VIEW_PNG_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PNG_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-png-to-pdf.svg',
        title: () => i10n._VIEW_PNGTOPDF_TITLE,
        subtitle: () => i10n._VIEW_PNGTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '08B933F7-BB94-4A4E-AA5B-7598096EF009',
        status: ToolStatus.None,
        ratingIcon: '/images/png-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "PNGTOPDF",
        uploadFileIcon: '/images/white-page-upload-png.svg',
        get uploadFileTypes () { return  fileFormats.png },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.pngToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkPngPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkPngPdf(), class: colorClass };
            const column3 = { title: i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkPngPdf(), class: colorClass };
    
            return [column1, column2, column3]; 
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    dxfToPdf: {
        route: sitemap.VIEW_DXF_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_DXF_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-dxf-to-pdf.svg',
        title: () => i10n._VIEW_DXFTOPDF_TITLE,
        subtitle: () => i10n._VIEW_DXFTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '923FFA9C-6E0F-4BF7-A6B0-BAB10F0B99C0',
        status: ToolStatus.None,
        ratingIcon: '/images/dxf-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "DXFTOPDF",
        uploadFileIcon: '/images/white-page-upload-dxf.svg',
        get uploadFileTypes () { return  fileFormats.dxf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.dxfToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkDxf(), class: colorClass };
            const column2 = { title: i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkDxf(), class: colorClass };
            const column3 = { title: i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkDxf(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.cad,
        merchandise2: PSPDFKitAnchor.cad
    },
    removeInteractiveContent: {
        route: sitemap.VIEW_REMOVE_INTERACTIVE_CONTENT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REMOVE_INTERACTIVE_CONTENT_ROUTE),
        actionUrl: `tools/${sitemap.REMOVE_INTERACTIVE_CONTENT_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-remove-interactive.svg',
        title: () => i10n._VIEW_REMOVEINTERACTIVECONTENT_TITLE,
        subtitle: () => i10n._VIEW_REMOVEINTERACTIVECONTENT_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '738652D8-3A1C-4BED-90D9-C77258F2DE9D',
        status: ToolStatus.None,
        ratingIcon: '/images/remove-interactive-content-pdf-rating.png',
        howToUseLocalizationKeyPart: "REMOVEINTERACTIVECONTENT",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_REMOVEINTERACTIVECONTENT_LOADING,
        category: [toolCategories.optimize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.optimization,
        merchandise2: PSPDFKitAnchor.optimization
    },
    removeDocumentContent: {
        route: sitemap.VIEW_REMOVE_DOCUMENT_CONTENT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REMOVE_DOCUMENT_CONTENT_ROUTE),
        actionUrl: `tools/${sitemap.REMOVE_DOCUMENT_CONTENT_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-remove-embeds.svg',
        title: () => i10n._VIEW_REMOVEDOCUMENTCONTENT_TITLE,
        subtitle: () => i10n._VIEW_REMOVEDOCUMENTCONTENT_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'C0333D96-7E8A-49ED-9589-900234429192',
        status: ToolStatus.None,
        ratingIcon: '/images/remove-pdf-content-rating.png',
        howToUseLocalizationKeyPart: "REMOVEDOCUMENTCONTENT",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_REMOVEDOCUMENTCONTENT_LOADING,
        category: [toolCategories.optimize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    pdfToText: {
        route: sitemap.VIEW_PDF_TO_TEXT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_PDF_TO_TEXT_ROUTE),
        actionUrl: `tools/${sitemap.PDF_TO_TEXT_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-pdf-to-txt.svg',
        title: () => i10n._VIEW_PDFTOTEXT_TITLE,
        subtitle: () => i10n._VIEW_PDFTOTEXT_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '2CCDB984-EFB9-4220-A9E1-B17FB2FDAB36',
        status: ToolStatus.None,
        ratingIcon: '/images/pdf-to-text-rating.png',
        howToUseLocalizationKeyPart: "PDFTOTEXT",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_PDFTOTEXT_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    textToPdf: {
        route: sitemap.VIEW_TEXT_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_TEXT_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.TEXT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-text-to-pdf.svg',
        title: () => i10n._VIEW_TEXTTOPDF_TITLE,
        subtitle: () => i10n._VIEW_TEXTTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '355FE458-BC5C-43B7-B94C-9372637C84CD',
        status: ToolStatus.None,
        ratingIcon: '/images/text-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "TEXTTOPDF",
        uploadFileIcon: '/images/white-page-upload-txt.svg',
        get uploadFileTypes () { return  fileFormats.txt },
        uploadMultipleFiles: false,
        uploadAvoidPreload: true,
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_TEXTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.converter,
        merchandise2: PSPDFKitAnchor.converter
    },
    markupPdf: {
        route: sitemap.VIEW_MARKUP_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_MARKUP_PDF_ROUTE),
        actionUrl: `tools/${sitemap.DOCUVIEWARE_SAVE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-markup-pdf.svg',
        title: () => i10n._VIEW_MARKUPPDF_TITLE,
        subtitle: () => i10n._VIEW_MARKUPPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '4563570F-9159-4E4A-8627-F18A182BBE38',
        status: ToolStatus.None,
        ratingIcon: '/images/markup-pdf-rating.png',
        howToUseLocalizationKeyPart: "MARKUPPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_MARKUPPDF_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.none
        },
        merchandise: GdPictureAnchor.xmp,
        merchandise2: PSPDFKitAnchor.xmp
    },
    convertToGrayscale: {
        route: sitemap.VIEW_CONVERT_TO_GRAYSCALE,
        url: () => getLocalizedLink(sitemap.VIEW_CONVERT_TO_GRAYSCALE),
        actionUrl: `tools/${sitemap.CONVERT_TO_GRAYSCALE_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-greyscale.svg',
        title: () => i10n._VIEW_CONVERTTOGRAYSCALE_TITLE,
        subtitle: () => i10n._VIEW_CONVERTTOGRAYSCALE_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '761FB0FD-4B2A-49E7-B462-DAB419ABE29E',
        status: ToolStatus.None,
        ratingIcon: '/images/convert-to-grayscale-rating.png',
        howToUseLocalizationKeyPart: "CONVERTTOGRAYSCALE",
        uploadFileIcon: '/images/white-page-upload-generic-files.svg',
        get uploadFileTypes () { return  fileFormats.all },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOGRAYSCALE_LOADING,
        category: [toolCategories.convert],
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },        
        get didYouKnow() {
            const colorClass = tools.convertToGrayscale.fillClassName;
            const columns = getColumnList(tools.convertToGrayscale.howToUseLocalizationKeyPart);
    
            const column1 = { title: columns[0].title, body: renderFirstColumnWithAddedLinkGrayscale(), class: colorClass };
            const column2 = { title: columns[1].title, body: renderSecondColumnWithAddedLinkGrayscale(), class: colorClass };
            const column3 = { title: columns[2].title, body: renderThirdColumnWithAddedLinkGrayscale(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.imageProcessing,
        merchandise2: PSPDFKitAnchor.imageProcessing
    },
    dicomToPdf: {
        route: sitemap.VIEW_DICOM_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_DICOM_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-dicom-to-pdf.svg',
        title: () => i10n._VIEW_DICOMTOPDF_TITLE,
        subtitle: () => i10n._VIEW_DICOMTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '44D8BA38-9A66-43D2-8336-1A775E719EB6',
        status: ToolStatus.None,
        ratingIcon: '/images/dicom-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "DICOMTOPDF",
        uploadFileIcon: '/images/white-page-upload-dicom.svg',
        get uploadFileTypes () { return  fileFormats.dicom },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.dicomToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnDicom(), class: colorClass };
            const column2 = { title: i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnDicom(), class: colorClass };
            const column3 = { title: i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnDicom(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.dicom,
        merchandise2: PSPDFKitAnchor.dicom
    },
    combineToPdfPortfolio: {
        route: sitemap.VIEW_COMBINE_TO_PDF_PORTFOLIO_ROUTE,
        url:  "#",
        actionUrl: ``,
        image: '/images/white-avepdf-pdf-portfolio.svg',
        title: () => i10n._VIEW_COMBINETOPDFPORTFOLIO_TITLE,
        subtitle: () => i10n._VIEW_COMBINETOPDFPORTFOLIO_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '81E4B44F-1D85-4506-A2E3-8F523EE8E087',
        status: ToolStatus.ComingSoon,
        ratingIcon: '/images/clean-up-pdf-rating.png',
        howToUseLocalizationKeyPart: "COMBINETOPDFPORTFOLIO",
        uploadFileIcon: '/images/white-page-upload-files.svg',
        get uploadFileTypes () { return {  ext: window.AvePDFAppState.ImportExtensions.join(',') } }, //set correct during implementation
        uploadMultipleFiles: true, //set correct during implementation
        loadingSubTitle: () => i10n._VIEW_CONVERTTOGRAYSCALE_LOADING, //set correct during implementation
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        }
    },
    extractPdfPages: {
        route: sitemap.VIEW_EXTRACT_PDF_PAGES_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_EXTRACT_PDF_PAGES_ROUTE),
        actionUrl: `tools/${sitemap.EXTRACT_PDF_PAGES_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-extract-pages.svg',
        title: () => i10n._VIEW_EXTRACTPDFPAGES_TITLE,
        subtitle: () => i10n._VIEW_EXTRACTPDFPAGES_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'EA2EA865-5A8C-41C5-B2C7-076040183F49',
        status: ToolStatus.None,
        ratingIcon: '/images/extract-pdf-pages-rating.png',
        howToUseLocalizationKeyPart: "EXTRACTPDFPAGES",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_EXTRACTPDFPAGES_LOADING,
        thumbnail: {
            mode: thumbnailMode.pages,
            width: 250,
            height: 250
        },
        category: [toolCategories.edit],
        get blogArticles() {
            return [
                blogArticles.split
            ]
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    svgToPdf: {
        route: sitemap.VIEW_SVG_TO_PDF_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_SVG_TO_PDF_ROUTE),
        actionUrl: `tools/${sitemap.CONVERT_TO_PDF_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-svg-to-pdf.svg',
        title: () => i10n._VIEW_SVGTOPDF_TITLE,
        subtitle: () => i10n._VIEW_SVGTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'BE715B9E-C81A-4EE7-8BF6-CA473D1B0A1F',
        status: ToolStatus.None,
        ratingIcon: '/images/svg-to-pdf-rating.png',
        howToUseLocalizationKeyPart: "SVGTOPDF",
        uploadFileIcon: '/images/white-page-upload-svg.svg',
        get uploadFileTypes () { return  fileFormats.svg },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.svgToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnSvgPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnSvgPdf(), class: colorClass };
    
            return [column1, column2];
        },
        merchandise: GdPictureAnchor.svg,
        merchandise2: PSPDFKitAnchor.svg
    },
    optimizeForWeb: {
        route: sitemap.VIEW_OPTIMIZE_PDF_FOR_WEB_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_OPTIMIZE_PDF_FOR_WEB_ROUTE),
        actionUrl: `tools/${sitemap.OPTIMIZE_PDF_FOR_WEB_ACTION_ROUTE}`,
        image: '/images/51-white-optimize-for-web.svg',
        title: () => i10n._VIEW_LINEARIZEPDF_TITLE,
        subtitle: () => i10n._VIEW_LINEARIZEPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '61CA6762-B120-4077-BF7F-FC62B4C0A0B6',
        status: ToolStatus.None,
        ratingIcon: '/images/optimize-for-web-rating.png',
        howToUseLocalizationKeyPart: "LINEARIZEPDF",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_LINEARIZEPDF_LOADING,
        showFileDetail: true,
        category: [toolCategories.optimize],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.optimizeForWeb.fillClassName;
            const column1 = { title: i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnOptimize(), class: colorClass };
            const column2 = { title: i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnOptimize(), class: colorClass };
            const column3 = { title: i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnOptimize(), class: colorClass };
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.optimization,
        merchandise2: PSPDFKitAnchor.optimization
    },
    removeText: {
        route: sitemap.VIEW_REMOVE_TEXT_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_REMOVE_TEXT_ROUTE),
        actionUrl: `tools/${sitemap.REMOVE_TEXT_ACTION_ROUTE}`,
        image: '/images/white-avepdf-svg-remove-hidden-text.svg',
        title: () => i10n._VIEW_REMOVETEXT_TITLE,
        subtitle: () => i10n._VIEW_REMOVETEXT_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '1F4618D2-B763-4C5D-AE40-E9A33024B846',
        status: ToolStatus.None,
        ratingIcon: '/images/remove-hidden-text-rating.png',
        howToUseLocalizationKeyPart: "REMOVETEXT",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        showFileDetail: true,
        loadingSubTitle: () => i10n._VIEW_REMOVETEXT_LOADING,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.removeText.fillClassName;

            const column1 = { title: i10n._VIEW_REMOVETEXTPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnRemoveText(), class: colorClass };
            const column2 = { title: i10n._VIEW_REMOVETEXTPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnRemoveText(), class: colorClass };
            const column3 = { title: i10n._VIEW_REMOVETEXTPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnRemoveText(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    editPdfMeta: {
        route: sitemap.VIEW_EDIT_PDF_META_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_EDIT_PDF_META_ROUTE),
        actionUrl: `tools/${sitemap.EDIT_PDF_META_ACTION_ROUTE}`,
        image: '/images/56-white-avepdf-svg-edit-pdf-metadata.svg',
        title: () => i10n._VIEW_EDITPDFMETA_TITLE,
        subtitle: () => i10n._VIEW_EDITPDFMETA_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '26C4584F-BF9D-46C3-B5F3-1A31B093C25D',
        status: ToolStatus.None,
        ratingIcon: '/images/edit-pdf-metadata-rating.png',
        howToUseLocalizationKeyPart: "EDITPDFMETA",
        uploadFileIcon: '/images/white-page-upload-pdf.svg',
        get uploadFileTypes () { return  fileFormats.pdf },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_EDITPDFMETA_LOADING_SUBTITLE,
        showFileDetail: true,
        category: [toolCategories.edit],
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.editPdfMeta.fillClassName;

            const column1 = { title: i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnEditPdfMeta(), class: colorClass };
            const column2 = { title: i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnEditPdfMeta(), class: colorClass };
            const column3 = { title: i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnEditPdfMeta(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.editing,
        merchandise2: PSPDFKitAnchor.editing
    },
    barcodeReco: {
        route: sitemap.VIEW_DOCUMENT_BARCODE_ROUTE,
        url: () => getLocalizedLink(sitemap.VIEW_DOCUMENT_BARCODE_ROUTE),
        actionUrl: `tools/${sitemap.DOCUMENT_BARCODE_ACTION_ROUTE}`,
        image: '/images/53-white-avepdf-svg-barcode-reading.svg',
        title: () => i10n._VIEW_BARCODERECO_TITLE,
        subtitle: () => i10n._VIEW_BARCODERECO_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '0173C52B-D25E-4540-A0CE-509D11FE23A9',
        status: ToolStatus.None,
        ratingIcon: '/images/add-barcode-qrcode-rating.png',
        howToUseLocalizationKeyPart: "BARCODERECO",
        uploadFileIcon: '/images/white-page-upload-img-pdf-files.svg',
        get uploadFileTypes () { return { ext: window.AvePDFAppState.ImportExtensions.filter(e => e.toLowerCase() !== ".txt").join(',') } },
        uploadMultipleFiles: false,
        loadingSubTitle: () => i10n._VIEW_BARCODERECO_LOADING,
        category: [toolCategories.other],
        uploadApiPreloadSection: "NONE",
        showFileDetail: true,
        thumbnail: {
            mode: thumbnailMode.none
        },
        get didYouKnow() {
            const colorClass = tools.barcodeReco.fillClassName;
            const column1 = { title: i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnBarcode(), class: colorClass };
            const column2 = { title: i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnBarcode(), class: colorClass };
    
            return [ column1, column2 ];
        },
        merchandise: GdPictureAnchor.barcode,
        merchandise2: PSPDFKitAnchor.barcode
    },
    htmlToPdf: {
        route: sitemap.VIEW_HTML_TO_PDF,
        view: 'tabs',
        url: () => getLocalizedLink(sitemap.VIEW_HTML_TO_PDF),
        actionUrl: `tools/${sitemap.CONVERT_FROM_HTML_ACTION_ROUTE}`,
        image: '/images/58-white-avepdf-svg-html-to-pdf.svg',
        title: () => i10n._VIEW_HTMLTOPDF_TITLE,
        subtitle: () => i10n._VIEW_HTMLTOPDF_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: 'AA53B854-70BF-43ED-B810-445E80D434E7',
        status: ToolStatus.None,
        ratingIcon: '/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "HTMLTOPDF",
        uploadFileIcon: '/images/black-generic-html-upload-file.svg',
        get uploadFileTypes () { return  fileFormats.html },
        uploadMultipleFiles: false,
        showFileDetail: true,
        uploadAvoidPreload: true,
        loadingSubTitle: () => i10n._VIEW_CONVERTTOPDF_LOADING,
        category: [toolCategories.convert],
        thumbnail: {
            mode: thumbnailMode.none
        },
        customUriPlaceholder: "https://www.example.com/",
        additionalUploadMethod: ["html-text"],
        get didYouKnow() {
            const colorClass = tools.htmlToPdf.fillClassName;

            const column1 = { title: i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_TITLE, body: renderFirstColumnWithAddedLinkHtmlPdf(), class: colorClass };
            const column2 = { title: i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_TITLE, body: renderSecondColumnWithAddedLinkHtmlPdf(), class: colorClass };
            const column3 = { title: i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_TITLE, body: renderThirdColumnWithAddedLinkHtmlPdf(), class: colorClass };
    
            return [column1, column2, column3];
        },
        merchandise: GdPictureAnchor.html,
        merchandise2: PSPDFKitAnchor.html
    },
    viewDocument: {
        route: sitemap.VIEW_DOCUMENT_VIEWER_ROUTE,
        view: 'tabs',
        url: () => getLocalizedLink(sitemap.VIEW_DOCUMENT_VIEWER_ROUTE),
        image: '/images/white-avepdf-svg-document-viewer.svg',
        title: () => i10n._VIEW_DOCUMENTVIEWER_TITLE,
        subtitle: () => i10n._VIEW_DOCUMENTVIEWER_SUBTITLE,
        loadingSubTitle : () => i10n._VIEW_DOCUMENTVIEWER_SUBTITLE,
        fillClassName: NextFillClassName(),
        id: '332DAB26-DC1D-47D3-AF1C-06F13AF7F51B',
        status: ToolStatus.None,
        ratingIcon: '/images/51-rating-avepdf-svg-html-to-pdf.png',
        howToUseLocalizationKeyPart: "DOCUMENTVIEWER",
        category: [toolCategories.other],
        merchandise: GdPictureAnchor.documentViewing,
        merchandise2: PSPDFKitAnchor.documentViewing
    },
};

/* Propositions of widgets after some tools */
//TODO: we could just keep [id, url, image, status, title, subtitle, fillClassName] fields instead of the whole object

tools.hyperCompress.offerAfterProcessed = [
    tools.cropPdf,
    tools.optimizeForWeb,
    tools.convertA
];

tools.split.offerAfterProcessed = [
    tools.combine,
    tools.merge,
];

tools.extractPdfPages.offerAfterProcessed = [
    tools.pdfToTiff,
    tools.pdfToJpg
]

tools.esign.offerAfterProcessed = [
    tools.flattenPdf,
    tools.resizePdf,
    tools.extractPdfPages
]

tools.convert.offerAfterProcessed = [
    tools.merge,
    tools.edit,
    tools.convertToGrayscale
];

tools.combine.offerAfterProcessed = [
    tools.organize,
    tools.ocr
];

tools.merge.offerAfterProcessed = [
    tools.organize,
    tools.convertToOnePagePdf,
    //tools.numbers
]

tools.organize.offerAfterProcessed = [
    tools.convertToOnePagePdf,
    //tools.numbers
]

tools.reversePdf.offerAfterProcessed = [
    //tools.numbers
    tools.numbers
]

tools.removePages.offerAfterProcessed = [
    //tools.numbers
]

tools.redactPdf.offerAfterProcessed = [
    tools.edit,
    tools.flattenPdf,
    tools.markupPdf
]

tools.convertA.offerAfterProcessed = [
    tools.validationA,
    tools.esign,
]

tools.edit.offerAfterProcessed = [
    tools.esign,
    tools.flattenPdf
]

tools.ocr.offerAfterProcessed = [
    tools.redactPdf,
    tools.pdfToText
]

tools.formFieldsFilling.offerAfterProcessed = [
    tools.esign,
    tools.flattenPdf
]

tools.compress.offerAfterProcessed = [
    tools.optimizeForWeb
]

tools.clean.offerAfterProcessed = [
    tools.ocr
]

tools.scanningToPdf.offerAfterProcessed = [
    tools.convertToGrayscale,
    tools.ocr,
    tools.clean,
    tools.edit,
    tools.markupPdf
];

tools.wordToPdf.offerAfterProcessed = [
    tools.esign,
    tools.convertA
];

tools.excelToPdf.offerAfterProcessed = [
    tools.esign,
    tools.resizePdf
];

tools.pptxToPdf.offerAfterProcessed = [
    tools.esign,
    tools.organize
];

tools.tiffToPdf.offerAfterProcessed = [
    tools.convertToGrayscale,
    tools.merge,
    tools.ocr
];

tools.jpgToPdf.offerAfterProcessed = [
    tools.convertToGrayscale,
    tools.merge,
    tools.ocr
];

tools.pngToPdf.offerAfterProcessed = [
    tools.convertToGrayscale,
    tools.merge,
    tools.ocr
];

tools.dxfToPdf.offerAfterProcessed = [
    tools.merge,
    tools.cropPdf,
    tools.convertToGrayscale
];

tools.textToPdf.offerAfterProcessed = [
    tools.esign,
    tools.rotate,
    tools.redactPdf,
    tools.cropPdf
]

tools.markupPdf.offerAfterProcessed = [
    tools.esign,
    tools.flattenPdf

]

tools.dicomToPdf.offerAfterProcessed = [
    tools.rotate,
    tools.cropPdf
]

tools.svgToPdf.offerAfterProcessed = [
    tools.convertToGrayscale,
    tools.edit,
    tools.cropPdf
]

tools.removeText.offerAfterProcessed = [
    tools.redactPdf
]

tools.htmlToPdf.offerAfterProcessed = [
    tools.cropPdf,
    tools.edit
]
tools.imageToExcel.offerAfterProcessed = [
    tools.excelToPdf
]

/* Propositions of widgets for all tools */
export const globalOfferAfterProcessed = [
    tools.hyperCompress,
    tools.protect
]

/* Exclusion of global propositions for some tools */
tools.imageToExcel.notOfferAfterProcessed = [
    tools.protect.id
]
tools.hyperCompress.notOfferAfterProcessed = [
    tools.hyperCompress.id
]

tools.convert.notOfferAfterProcessed = [
    tools.protect.id
];

tools.split.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.protect.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.unlock.notOfferAfterProcessed = [
    tools.protect.id
]

tools.pdfToTiff.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.pdfToJpg.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.pdfToPng.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.pdfToText.notOfferAfterProcessed = [
    tools.hyperCompress.id,
    tools.protect.id
]

tools.scanningToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

tools.tiffToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

tools.jpgToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

tools.pngToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

tools.dxfToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

tools.svgToPdf.notOfferAfterProcessed = [
    tools.protect.id
];

/* Exclusion of global propositions for some tools */
tools.convertA.notOfferAfterProcessed = [
    tools.protect.id
]

tools.pdfToWord.notOfferAfterProcessed = [
    tools.protect.id,
    tools.hyperCompress
]

tools.pdfToExcel.notOfferAfterProcessed = [
    tools.protect.id,
    tools.hyperCompress
]

tools.pdfToPowerPoint.notOfferAfterProcessed = [
    tools.protect.id,
    tools.hyperCompress
]


/* On fail global propositions for some tools */
tools.validationA.offerAfterFail = [
    tools.convertA
];

export default tools;