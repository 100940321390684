import { useEffect } from "react";
import config from '../../config';

export function AsyncSVG({
    className = "",
    id,
    src,
    onClick,
    enablessr/*not used yet, by here for futur purpose*/
}) {

    const elementName = src.split("/")[2].replace(".", "-");
    useEffect(() => {
        if (window.avepdfsvgs == null) {
            window.avepdfsvgs = [];
        }
        var ssr = config.isSnapshootingCrawlers || config.isSnapshootingUsers;

        if ((!ssr) || (enablessr && ssr)) {

            if (window.avepdfsvgs[elementName] != null) {
                Array.from(document.getElementsByClassName(elementName)).forEach((element) => {
                    element.innerHTML = window.avepdfsvgs[elementName]; //already in cache -> immediate rendering, don't trigger async request.
                });
            } else {
                //deferred rendering.
                const oReq = new XMLHttpRequest();
                oReq.onload = function () {
                    const responseText = this.responseText;
                    Array.from(document.getElementsByClassName(elementName)).forEach((element) => {
                        element.innerHTML = responseText;
                        window.avepdfsvgs[elementName] = responseText;
                    });
                };
                oReq.open("get", src, true);
                oReq.send();
            }
        }
    }, []);

    return <span onClick={onClick} id={id} className={`async-svg-container ${elementName} ${className} `}></span>
}