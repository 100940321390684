const sitemap = {
    "HOME_CONTROLLER_ROUTE": "",
    "ROUTER_CONTROLLER_ROUTE": "router",
    "FILES_CONTROLLER_ROUTE": "file",
    "TOOLS_CONTROLLER_ROUTE": "tools",
    "DOCUVIEWARE_CONTROLLER_ROUTE": "docuvieware",
    "ACCOUNT_CONTROLLER_ROUTE": "account",
    "ONEDRIVE_SDK_ROUTE": "onedrive",
    "VIEW_COMPRESS_PDF_ROUTE": "compress-pdf",
    "VIEW_MERGE_PDF_ROUTE": "merge-pdf",
    "VIEW_SPLIT_PDF_ROUTE": "split-pdf",
    "VIEW_ROTATE_PDF_ROUTE": "rotate-pdf",
    "VIEW_UNLOCK_PDF_ROUTE": "unlock-pdf",
    "VIEW_LOCK_PDF_ROUTE": "lock-pdf",
    "VIEW_REMOVE_PDF_PAGES_ROUTE": "remove-pages",
    "VIEW_ORGANIZE_PDF_ROUTE": "organize-pdf",
    "VIEW_ESIGN_PDF_ROUTE": "esign-pdf",
    "VIEW_PDFMRC_ROUTE": "hyper-compress-pdf",
    "VIEW_CONVERT_TO_PDF_ROUTE": "convert-to-pdf",
    "VIEW_CONVERT_TO_PDF_A_ROUTE": "convert-to-pdfa",
    "VIEW_JPG_TO_PDF_ROUTE": "jpg-to-pdf",
    "VIEW_TIFF_TO_PDF_ROUTE": "tiff-to-pdf",
    "VIEW_PNG_TO_PDF_ROUTE": "png-to-pdf",
    "VIEW_DXF_TO_PDF_ROUTE": "dxf-to-pdf",
    "VIEW_WORD_TO_PDF_ROUTE": "word-to-pdf",
    "VIEW_EXCEL_TO_PDF_ROUTE": "excel-to-pdf",
    "VIEW_COMBINE_PDF_ROUTE": "combine-to-pdf",
    "VIEW_OCR_ROUTE": "pdf-ocr",
    "VIEW_PPTX_TO_PDF_ROUTE": "pptx-to-pdf",
    "VIEW_PDF_TO_TIFF_ROUTE": "pdf-to-tiff",
    "VIEW_PDF_TO_JPEG_ROUTE": "pdf-to-jpeg",
    "VIEW_FLATTEN_PDF_ROUTE": "flatten-pdf",
    "VIEW_CROP_PDF_ROUTE": "crop-pdf",
    "VIEW_RESIZE_PDF_ROUTE": "resize-pdf",
    "VIEW_LOGIN_ROUTE": "login",
    "VIEW_SIGNUP_ROUTE": "signup",
    "VIEW_REMOVE_DOCUMENT_CONTENT_ROUTE": "remove-pdf-content",
    "VIEW_SCAN_TO_PDF_ROUTE": "scan-to-pdf",
    "VIEW_FILL_OUT_PDF_FORMS_ROUTE": "fill-out-pdf-forms",
    "VIEW_REMOVE_INTERACTIVE_CONTENT_ROUTE": "remove-interactive-content",
    "VIEW_REMOVE_TEXT_ROUTE": "remove-text",
    "VIEW_DOCUMENT_VIEWER_ROUTE": "view-document",
    "VIEW_DOCUMENT_BARCODE_ROUTE": "barcode-reading",
    "VIEW_DOCUMENT_PDFA_VALIDATOR_ROUTE": "pdfa-validation",
    "VIEW_PDF_TO_TEXT_ROUTE": "pdf-to-text",
    "VIEW_TEXT_TO_PDF_ROUTE": "text-to-pdf",
    "VIEW_CLEANUP_PDF_ROUTE": "cleanup-pdf",
    "VIEW_MARKUP_PDF_ROUTE": "pdf-annotator",
    "VIEW_PDF_REPAIR_ROUTE": "pdf-repair",
    "VIEW_PDF_EDIT_ROUTE": "pdf-edit",
    "VIEW_INSERT_NEW_PAGE_ROUTE": "insert-page",
    "VIEW_NUP_PDF_ROUTE": "pdf-n-up",
    "VIEW_CONVERT_TO_ONE_PAGE_PDF_ROUTE": "convert-to-one-page-pdf",
    "VIEW_OPTIMIZE_PDF_FOR_WEB_ROUTE": "optimize-pdf-for-web",
    "VIEW_ORGANIZE_PDF_ROUTE": "organize-pdf",
    "VIEW_PDFMRC_ROUTE": "hyper-compress-pdf",
    "VIEW_PDF_EDIT_ROUTE": "pdf-edit",
    "VIEW_PDF_REPAIR_ROUTE": "pdf-repair",
    "VIEW_PDF_TO_JPEG_ROUTE": "pdf-to-jpeg",
    "VIEW_PDF_TO_PNG_ROUTE": "pdf-to-png",
    "VIEW_EDIT_PDF_META_ROUTE": "edit-pdf-meta",
    "GET_PDF_INFO_ROUTE": "get-pdf-info",
    "VIEW_REVERSE_PDF_ROUTE": "reverse-pdf",
    "VIEW_PAGING_PDF_ROUTE": "paging-pdf",
    "VIEW_DICOM_TO_PDF_ROUTE": "dicom-to-pdf",
    "VIEW_REDACT_PDF_ROUTE": "redact-pdf",
    "VIEW_EXTRACT_PDF_PAGES_ROUTE": "extract-pdf-pages",
    "VIEW_SVG_TO_PDF_ROUTE": "svg-to-pdf",
    "VIEW_CONVERT_TO_GRAYSCALE": "convert-pdf-to-grayscale",
    "VIEW_HTML_TO_PDF": "html-to-pdf",
    "VIEW_WATERMARK_PDF_ROUTE": "watermark-pdf",
    "VIEW_LIST_PLAN_ROUTE": "pricing",
    "VIEW_PROFILE_ROUTE": "account",
    "VIEW_THANK_YOU_ROUTE": "thank-you",
    "VIEW_PURCHASE_ISSUE_ROUTE": "buy-plan-error",
    "VIEW_ACCOUNT_UPDATE_PROFILE_ROUTE": "update-profile",
    "VIEW_IMAGE_TO_EXCEL_ROUTE": "image-to-excel",
    "VIEW_PDF_TO_WORD_ROUTE": "pdf-to-word",
    "VIEW_PDF_TO_EXCEL_ROUTE": "pdf-to-excel",
    "VIEW_PDF_TO_POWERPOINT_ROUTE": "pdf-to-powerpoint",
    "VIEW_ACCOUNT_MANAGE_SUBSCRIPTION": "manage-subscription",
    
    "COMPRESS_PDF_ACTION_ROUTE": "compress-pdf-action",
    "MERGE_PDF_ACTION_ROUTE": "merge-pdf-action",
    "SPLIT_PDF_ACTION_ROUTE": "split-pdf-action",
    "ROTATE_PDF_ACTION_ROUTE": "rotate-pdf-action",
    "UNLOCK_PDF_ACTION_ROUTE": "unlock-pdf-action",
    "LOCK_PDF_ACTION_ROUTE": "lock-pdf-action",
    "REMOVE_PDF_PAGES_ACTION_ROUTE": "remove-pages-action",
    "ORGANIZE_PDF_ACTION_ROUTE": "organize-pdf-action",
    "ESIGN_PDF_ACTION_ROUTE": "esign-pdf-action",
    "PDFMRC_ACTION_ROUTE": "hyper-compress-action",
    "CONVERT_TO_PDF_ACTION_ROUTE": "convert-to-pdf-action",
    "CONVERT_TO_PDF_A_ACTION_ROUTE": "convert-to-pdfa-action",
    "COMBINE_PDF_ACTION_ROUTE": "combine-to-pdf-action",
    "OCR_ACTION_ROUTE": "pdf-ocr-action",
    "PDF_TO_TIFF_ACTION_ROUTE": "pdf-to-tiff-action",
    "PDF_TO_JPEG_ACTION_ROUTE": "pdf-to-jpeg-action",
    "GET_IMAGE_CROPPED": "get-image-cropped",
    "FLATTEN_PDF_ACTION_ROUTE": "flatten-pdf-action",
    "CROP_PDF_ACTION_ROUTE": "crop-pdf-action",
    "RESIZE_PDF_ACTION_ROUTE": "resize-pdf-action",
    "REMOVE_DOCUMENT_CONTENT_ACTION_ROUTE": "remove-pdf-content-action",
    "DOCUVIEWARE_SAVE_ACTION_ROUTE": "docuvieware-save-action",
    "REMOVE_INTERACTIVE_CONTENT_ACTION_ROUTE": "remove-interactive-content-action",
    "PDF_TO_TEXT_ACTION_ROUTE": "pdf-to-text-action",
    "TEXT_TO_PDF_ACTION_ROUTE": "text-to-pdf-action",
    "EXTRACT_PDF_PAGES_ACTION_ROUTE": "extract-pdf-pages-action",
    "PDF_REPAIR_ACTION_ROUTE": "pdf-repair-action",
    "CONVERT_TO_ONE_PAGE_PDF_ACTION_ROUTE": "convert-to-one-page-pdf-action",
    "PDF_EDIT_ACTION_ROUTE": "pdf-edit",
    "NUP_PDF_ACTION_ROUTE": "pdf-n-up",
    "INSERT_NEW_PAGE_ACTION_ROUTE": "insert-page-action",
    "REMOVE_TEXT_ACTION_ROUTE": "remove-text-action",
    "OPTIMIZE_PDF_FOR_WEB_ACTION_ROUTE": "optimize-pdf-for-web-action",
    "REVERSE_PDF_ACTION_ROUTE": "reverse-pdf-action",
    "PDF_TO_PNG_ACTION_ROUTE": "pdf-to-png-action",
    "EDIT_PDF_META_ACTION_ROUTE": "edit-pdf-meta-action",
    "PAGING_PDF_ACTION_ROUTE": "paging-pdf-action",
    "DOCUMENT_BARCODE_ACTION_ROUTE": "barcode-recognition-action",
    "PDFA_VALIDATOR_ACTION_ROUTE": "pdfa-validator-action",
    "CONVERT_TO_GRAYSCALE_ACTION_ROUTE": "convert-grayscale-action",
    "WATERMARK_PDF_ACTION_ROUTE": "watermark-pdf-action",
    "IMAGE_TO_EXCEL_ACTION_ROUTE": "image-to-excel-action",
    "PDF_TO_WORD_ACTION_ROUTE": "pdf-to-word-action",
    "PDF_TO_EXCEL_ACTION_ROUTE": "pdf-to-excel-action",
    "PDF_TO_POWERPOINT_ACTION_ROUTE": "pdf-to-powerpoint-action",


    "GET_EMBED_CODE_SNIPPET": "get-embed-code-snippet",
    "DOCUVIEWARE_GET_CONTROL_ACTION": "docuvieware-get-control",
    "VIEW_NOT_FOUND_ROUTE": "404",
    "UPLOAD_ROUTE": "upload",
    "PREPARE_FILE_FOR_TOOL_ROUTE": "prepare-file-for-tool",
    "LOAD_FROM_HTTP": "load-from-http",
    "REDIRECT_OUTPUT_FILE_ROUTE": "redirectOutputFile",
    "DOWNLOAD_CLIENT_ROUTE": "downloadClient",
    "DOWNLOAD_PREVIEW_ROUTE": "downloadPreview",
    "REMOVE_FILE_CLIENT_ROUTE": "removeFile",
    "VIEW_ROUTER_ACCESS_DENIED_ROUTE": "access-denied",
    "VIEW_INTERNAL_SERVER_ERROR_ROUTE": "500",
    "VIEW_HOME_INDEX_ROUTE": "",
    "VIEW_HOME_ERROR_ROUTE": "error",
    "VIEW_HOME_COOKIE_POLICY_ROUTE": "cookie-policy",
    "HOME_DEFAULT_ROUTE": "~/{*lang}",
    "CATCH_ALL": "{*url}",
    "COOKIE_LOADER_FOR_SAFARI": "cookie-loader-for-safari-iframe",
    "VIEW_ACCOUNT_LOGIN_ROUTE": "login",
    "VIEW_ACCOUNT_REGISTER_ROUTE": "register",
    "VIEW_ACCOUNT_FORGOT_PASSWORD_ROUTE": "forgot-password",
    "VIEW_ACCOUNT_EXTERNAL_LOGIN_CALLBACK_ROUTE": "external-login-confirm-callback",
    "VIEW_ACCOUNT_CONFIRM_EMAIL_ROUTE": "confirm-email",
    "VIEW_ACCOUNT_RESET_PASSWORD_ROUTE": "reset-password",
    "ACCOUNT_LOGIN_ACTION_ROUTE": "login-action",
    "ACCOUNT_REGISTER_ACTION_ROUTE": "register-action",
    "ACCOUNT_LOGOUT_ACTION_ROUTE": "logout-action",
    "ACCOUNT_EXTERNAL_LOGIN_ACTION_ROUTE": "external-login-action",
    "ACCOUNT_EXTERNAL_LOGIN_CALLBACK_ACTION_ROUTE": "external-login-callback-action",
    "ACCOUNT_EXTERNAL_LOGIN_CALLBACK_CONFIRM_ACTION_ROUTE": "external-login-callback-confirm-action",
    "ACCOUNT_CONFIRM_EMAIL_ACTION_ROUTE": "confirm-email-action",
    "ACCOUNT_FORGOT_PASSWORD_ACTION_ROUTE": "forgot-password-action",
    "ACCOUNT_RESET_PASSWORD_ACTION_ROUTE": "reset-password-action",
    "ACCOUNT_BUY_PLAN": "purchase-plan",
    "ACCOUNT_LOGGED_ACTION_ROUTE": "is-logged-on",
    "ACCOUNT_GET_USER_INFO": "get-user-info",
    "ACCOUNT_GET_PLANS_PRICE": "get-plans-price",
    "ACCOUNT_UPDATE_USER_INFO": "update-user-info",
    "ACCOUNT_GET_SUBSCRIPTION_STATUS": "get-subscription-status",
    "ACCOUNT_CANCEL_PLAN": "cancel-plan-action",
    "FILE_USAGE": "usage"
}

export default sitemap;